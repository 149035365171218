import React from 'react'
import styled, {css} from 'styled-components'
import {media} from '../../utils/Media'

const GroupStyles = styled.section`
  width: 100%;
  background-color: ${props => props.theme.colors.grey};
  padding: 2rem 0;

  section {
    max-width: 1000px;
    margin: 0 auto;
    text-align: center;

    h3 {
      color: ${props => props.theme.colors.secondary};
      font-size: 3rem !important;
      @media ${media.md} {
        font-size: 4rem !important;
      }
    }

    strong {
      font-family: ${props => props.theme.font.family.body} !important;
      margin: .5rem 0;
      display: block;
      font-size: 1.5rem;

      br {
        @media ${media.md} {
          display: none;
        }
      }
    }

    .smaller-title {
      width: 90%;
      margin: 0 auto;
      @media ${media.md} {
        font-size: 1.25rem !important;
      }
    }

    .dates {
      margin: 1.5rem 0 1.5rem 0;
      font-size: 1.5rem;
      @media ${media.md} {
        font-size: 1.75rem !important;
      }
    }

    .small-text {
      width: 80%;
      margin: 1rem auto 0 auto;
      @media ${media.md} {
        font-size: 1rem !important;
        width: 60%;
        a {
          font-size: 1rem !important;
        }
      }
    }
  }
`
const TicketBoxWrapper = styled.div`
  width: 90%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 1rem;
  @media ${media.md} {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
  }
`
const TicketBoxStyles = styled.div`
  width: ${props => props.alt ? "90%" : "100%"};

  margin: 0 auto;
  border-radius: 4.89px;
  padding: 1.5rem;
  background-color: #fff;

  div {
    border: solid 1px ${props => props.theme.colors.secondary};
    padding-bottom: 1rem;

    h5 {
      padding: 1.5rem 0;
      background-color: ${props => props.theme.colors.secondary};
      font-family: ${props => props.theme.font.family.body} !important;
      font-weight: 700;
      color: #fff;
      font-size: 1.25rem !important;
      text-transform: uppercase;
      @media ${media.md} {
        font-size: 1.25rem !important;
      }
    }

    p {
      font-weight: 800;
      padding: .45rem 0;
      margin: 0;
      @media ${media.md} {
        font-size: 1.25rem !important;
      }
    }
  }

  .dates {
    margin: 0;
    padding: 0;
    border: none;

    div {
      margin: 0;
      padding: 0;
      border: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      border-bottom: solid 1px #B71314;
      @media ${media.md} {
        flex-direction: row;
        width: 90%;
        margin: 0 auto;
        padding: .25rem 0;
      }
    }
  }

  ${props => props.alt && css`
    margin-bottom: 2rem;
  `}

`
const Groups = () => {
  return (
    <GroupStyles>
      <section>
        {/* <h3>GROUPS</h3> */}
        <h3>GROUP RATES</h3>
        <TicketBox
          alt="Monday-Thursday (excluding peak weeks**)"
          title="Monday-Thursday (excluding peak weeks**)"
        >
          <p><em>GROUPS OF 8+</em></p>
          <p>BAND A &amp; B - &pound;49.50</p>
          <p>BAND C &amp; D - &pound;35</p>
          <br/>
          <p><em>GROUPS OF 20+</em></p>
          <p>BAND A &amp; B - &pound;39.50</p>
          <p>BAND C &amp; D - &pound;29.50</p>
        </TicketBox>
        <TicketBox
          alt="FRIDAY (PLUS PEAK WEEKS** MONDAY-THURSDAY)"
          title="FRIDAY (PLUS PEAK WEEKS** MONDAY-THURSDAY)"
        >
          <p><em>GROUPS OF 8+</em></p>
          <p>BAND A &amp; B - &pound;59.50</p>
          <p>BAND C &amp; D - &pound;45</p>
          <br/>
          <p><em>GROUPS OF 20+</em></p>
          <p>BAND A &amp; B - &pound;49.50</p>
          <p>BAND C &amp; D - &pound;39.50</p>
        </TicketBox>
        
        
        <strong className="dates">Schools</strong>
        <p className="smaller-title mb-3">Monday-Thursday until 12 October 2023, excluding peak weeks</p>
        <TicketBox alt="GROUPS OF 10+" title="GROUPS OF 10+">
          <p>BANDS B, C, &amp; D - &pound;22.50</p>
          <p><em>PLUS ONE FREE TEACHER FOR EVERY 10 STUDENTS</em></p>
        </TicketBox>
        {/* <TicketBoxWrapper>
                    <TicketBox groupNo="8" ABPrice="49.50" CDPrice="35.00" />
                    <TicketBox groupNo="20" ABPrice="39.50" CDPrice="29.50" />
                </TicketBoxWrapper>
                <p className="small-text">‡ Available Monday - Thursday performances until 15 October, excluding peak performances</p>
                <strong className="dates">Friday &amp; Peak Performances</strong>
                <TicketBoxWrapper>
                    <TicketBox groupNo="8" ABPrice="59.50" CDPrice="45.00" />
                    <TicketBox groupNo="20" ABPrice="49.50" CDPrice="39.50" />
                </TicketBoxWrapper>
                <p className="small-text">‡ Available Friday performances and peak performances (see below) until 15 October</p>
                
                <TicketBox alt  />
                
    */}
        <TicketBox alt="ACCESS PERFORMANCES" title="ACCESS PERFORMANCES">
          <div className='dates'>
            {/* <div>
              <p>Wednesday 21 June, 7.30pm</p>
              <p>CAPTIONED</p>
            </div> */}
            <div>
              <p>Tuesday 11 July, 7.30pm</p>
              <p>SIGNED</p>
            </div>
            <div>
              <p>Saturday 16 September, 2.30pm</p>
              <p>AUDIO DESCRIBED</p>
            </div>
          </div>
        </TicketBox>
        <p className="small-text">** Peak week group rates apply to w/c 24 July – w/c 28 August and w/c 16 October 2023.
          No rates valid w/c 23 October 2023.</p>
        <p className="small-text">For all Group Bookings enquiries please email: <a
          href="mailto:groups@dominiontheatre.com" target="_blank"
          rel="noopener noreferrer"> groups@dominiontheatre.com</a></p>
        <p className="small-text">* Operated by Quay Tickets, Monday-Saturday 10am-6pm</p>
      </section>
    </GroupStyles>
  )
}

export default Groups


const TicketBox = ({groupNo, ABPrice, CDPrice, alt, title, children}) => (
  <TicketBoxStyles alt={alt}>
    {alt ? (
      <div>
        <h5>{title}</h5>
        <section>
          {children}
        </section>
      </div>
    ) : (
      <div>
        <h5>{`Groups of ${groupNo}+`}</h5>
        <section>
          <p>{`Band A & B - £${ABPrice} ‡`}</p>
          <p>{`Band C & D - £${CDPrice} ‡`}</p>
        </section>
      </div>
    )}
  </TicketBoxStyles>
)