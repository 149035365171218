import React from 'react'
import styled from 'styled-components'
import { media } from '../../utils/Media'

const AccessStyles = styled.section`
    width: 100%;
    section {
        max-width: 1000px;
        width: 90%;
        margin: 0 auto;
        padding: 3rem 0;
        text-align: center;
        h3 {
            color: ${props => props.theme.colors.secondary};
            font-size: 3rem!important;
            @media ${media.md} {
                font-size: 4rem!important;
            }
        }
        p {
            margin: 1.5rem auto;
            @media ${media.md} {
                width: 70%;
                font-size: 1.25rem!important;
                a {
                    font-size: 1.25rem!important;
                }
            }
        }
    }
`
const Access = () => {
    return (
        <AccessStyles>
            <section>
                <h3>ACCESS TICKETS</h3>
                <p>Signed – Tuesday 27 September 7.30pm</p>
                <p>The Dominion Theatre is committed to making the experience of all patrons as easy and enjoyable as possible. The Dominion Theatre has an Access List in place to make booking easy for anyone with access requirements.</p>
                <p>For full details and to find out more, please visit the Dominion Theatre’s dedicated Access <a href="https://www.nederlander.co.uk/dominion-theatre/plan-your-visit-dominion-theatre/access-and-facilities-dominion-theatre" target="_blank" rel="noopener noreferrer"> page here</a></p>
                <p>*Or +44 161 876 2405 if dialling from outside the UK. Operated by Quay Tickets, Monday – Friday, 9am-6pm and Saturday 10am-6pm</p>
            </section>
        </AccessStyles>
    )
}

export default Access
