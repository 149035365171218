import React from 'react'
import {Container} from 'reactstrap'
import styled from 'styled-components'
import {media} from '../../utils/Media'
import Dominion from "../../images/dominion.png"
import {Link} from 'gatsby'

const TicketsStyles = styled(Container)`
  width: 100%;
  padding: 1rem 0;

  section {
    text-align: center;

    p {
      font-family: ${props => props.theme.font.family.boldTall} !important;
      font-size: 2rem !important;
      color: ${props => props.theme.colors.grey1};

      br {
        @media ${media.md} {
          display: none;
        }
      }
    }

    .dominion {
      padding: 0 .5rem;
      width: 340px;
      @media ${media.md} {
        width: 490px;
      }
    }

    h2 {
      color: ${props => props.theme.colors.secondary};
      font-family: ${props => props.theme.font.family.boldTall} !important;
      font-size: 3rem !important;
      @media ${media.md} {
        font-size: 4rem !important;
      }
    }

  }

  .ticketBtns {
    margin: 1.5rem auto 0 auto;
    text-align: center;
    max-width: 1000px;

    h5 {
      font-weight: 700;
    }

    .display-none-desktop {
      @media ${media.md} {
        display: none ;
      }
    }

    &__grid {
      width: 90%;
      margin: 0 auto;
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 1rem;

      aside {
        background-color: ${props => props.theme.colors.secondary};
        border-radius: 4.9px;
        padding: .85rem;

        button {
          width: 100%;
          border-radius: 0 !important;
          background: none;
          border: solid 4.08px #fff;
          padding: .5rem .25rem;

          span {
            color: #fff !important;
            font-family: ${props => props.theme.font.family.boldTall};
            /* letter-spacing: 2px; */
            font-weight: 200;
            font-size: 2rem !important;

          }
        }

        a, a * {
          text-decoration: none !important;
        }


      }

      @media ${media.md} {
        grid-template-columns: repeat(2, 1fr);
        aside {
          button span {
            font-size: 3rem !important;
          }
        }
      }
    }

    .small-text {
      font-weight: 400;
      width: 90%;
      margin-left: auto;
      margin-right: auto;
      @media ${media.md} {
        font-size: 1rem !important;
        width: 60%;
      }
    }
  }
`

const Tickets = () => {
  const handleClick = () => {
  
  }
  return (
    <TicketsStyles>
      <section>
        <img src={Dominion}
             className="d-block mx-auto dominion my-3"
             alt=""
        />
        <h2 className="mt-4">TICKETS FROM £17.50</h2>
      </section>
      <div className="ticketBtns">
        <h5 className="my-4 display-none-desktop">2 Easy Ways To Book</h5>
        <div className="ticketBtns__grid">
          <aside>
            <a href="https://ticketing.greasemusical.co.uk/tickets/series/GreaseTheMusical" target="_blank"
               rel="noopener noreferrer">
              <button>
                <span>BOOK ONLINE NOW</span>
              </button>
            </a>
          </aside>
          <aside>
            <a href="tel:0345 200 7982" rel="noopener noreferrer">
              <button>
                {/* <span>CALL 0345 200 7982*</span> */}
                <span>CALL 020 7927 0965*</span>
              </button>
            </a>
          </aside>
        </div>
        <p className="small-text mt-4">
          No booking fees apply when purchasing online, by telephone or in person at the Dominion Theatre Box Office.
        </p>
      </div>
    </TicketsStyles>
  )
}

export default Tickets
