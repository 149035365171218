import React, {useState} from "react";
import Layout from "../components/Layout/Layout";
import Seo from "../utils/Seo";
import PageHeader from "../components/shared/PageHeader";
import styled from "styled-components";
import Tickets from "../components/tickets/tickets";
import {media} from "../utils/Media";
import Groups from "../components/tickets/groups";
import Access from "../components/tickets/access";
import {graphql, Link} from "gatsby";
import PerformanceSchedule from "../components/tickets/notPerforming";
import JasonPerformanceSchedule from "../components/tickets/jasonPerformance";
import {useEffect} from "react";
import Faq from "../components/tickets/faq";

const PageSubHeading = styled.section`
  width: 100%;
  background: ${(props) => props.theme.colors.grey};

  h1 {
    padding: 2rem 0;
    text-align: center;
    font-weight: 500;
    font-family: ${(props) => props.theme.font.family.boldTall} !important;
    font-size: 3rem !important;
    @media ${media.md} {
      font-size: 4rem !important;
    }
  }
`;
const PerformanceTimes = styled.section`
  width: 100%;
  background-color: #fff;
  padding: 2rem 0;

  h3 {
    text-transform: uppercase;
  }

  h3,
  p {
    text-align: center;
    color: #000;
  }

  p {
    line-height: 1.5;
    font-weight: 700;

    br {
      @media ${media.md} {
        display: none;
      }
    }

    span {
      display: none;
      @media ${media.md} {
        display: inline;
      }
    }
  }

  .smaller-text {
    font-size: 1rem !important;
    font-style: italic;
    @media ${media.md} {
      font-size: 1.2rem;
    }
  }

  .hover {
    cursor: pointer;

    &:hover {
      color: ${(props) => props.theme.colors.secondary};
      text-decoration: underline ${(props) => props.theme.colors.secondary};
    }
  }

  .button {
    width: 90%;
    margin: 1rem auto;
    max-width: 500px;

    aside {
      background-color: ${(props) => props.theme.colors.secondary};
      border-radius: 4.9px;
      padding: 0.85rem;

      button {
        width: 100%;
        border-radius: 0 !important;
        background: none;
        border: solid 4.08px #fff;
        padding: 0.5rem 0.25rem;

        span {
          color: #fff !important;
          font-family: ${(props) => props.theme.font.family.boldTall};
          /* letter-spacing: 2px; */
          text-transform: uppercase;
          font-weight: 200;
          font-size: 1.65rem;
          @media ${media.md} {
            font-size: 2rem;
          }
        }
      }

      a,
      a * {
        text-decoration: none !important;
      }
    }
  }

  .smaller {
    display: block;
    text-align: center;
    font-size: 17px !important;
    font-style: italic;
    color: #fff;
  }
`;
const TicketInfo = ({data}) => {
  const [open, setOpen] = useState(false);
  const [secondOpen, secondSetOpen] = useState(false);
  const faqData = data.faqs.nodes;
  const dates = data.notPerforming.nodes;
  const performingDates = data.jasonPerforming.nodes;
  
  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    }
    if (!open) {
      document.body.style.overflow = "auto";
    }
  }, [open]);
  
  return (
    <Layout>
      <Seo slug="/ticket-info" title="Ticket Info"/>
      <PageHeader/>
      {/* <PageSubHeading>
        <h1>TICKET INFO</h1>
      </PageSubHeading> */}
      {/* <Tickets/> */}
      <PerformanceTimes>
        <h3>Performance Times</h3>
        <p>Monday – Saturday 7.30pm, Thursday &amp; Saturday 2.30pm</p>
      </PerformanceTimes>
      <Groups/>
      {/* <Access/> */}
      <Faq data={faqData}/>
      {open ? <PerformanceSchedule setOpen={setOpen} data={dates}/> : ""}
      {secondOpen ? (
        <JasonPerformanceSchedule
          secondSetOpen={secondSetOpen}
          data={performingDates}
        />
      ) : (
        ""
      )}
    </Layout>
  );
};

export default TicketInfo;

export const TicketsQuery = graphql`
  query TICKETQUERY {
    faqs: allFaqsJson {
      nodes {
        section
        questions {
          question
          answer
        }
      }
    }
    dates: allDatesJson {
      nodes {
        Date
        Time
      }
    }
    notPerforming: allNotPerformingJson {
      nodes {
        month {
          text
          lastDate
          dates {
            date
            validDate
          }
        }
      }
    }
    jasonPerforming: allJasonPerformingJson {
      nodes {
        month {
          text
          startDate
          dates {
            date
          }
        }
      }
    }
  }
`;
