import React, { useState } from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ArrowIcon from '../../images/svgs/chevron-down-solid.svg'
import { media } from '../../utils/Media'

const FaqStyles = styled.section`
    width: 100%;
    margin: 2rem 0;
    section {
        width: 90%;
        margin: 0 auto;
        text-align: center;
        h3 {
            color: ${props => props.theme.colors.secondary};
            font-size: 3rem!important;
            @media ${media.md} {
                font-size: 4rem!important;
            }
        }
 
    }
    .wrapper {
            .question-block {
                max-width: 1000px;
                margin: 0 auto;
                padding: 1.5rem 0;
                h4 {
                    font-weight: 200;
                    text-transform: uppercase;
                    text-align: center;
                    font-family: ${props => props.theme.font.family.boldTall};
                    font-size: ${props => props.theme.font.size.xxl}!important;
                }
            }
        }
`
const SingleFaQStyles = styled.div`
cursor: pointer;
.question {
    border-bottom: solid 1px rgba(0,0,0,.15);

    width: 100%;
    padding: 1rem;
    position: relative;
    > p {
        width: 80%;
        margin-top: 10px;
        @media ${media.md} {
            font-size: 1.2rem!important;
        }
    }
    span {
        img {
            min-width: 20px;
            height: 20px;
            position: absolute;
            top: 50%;
            right: 20px;
            width: 24px;
            height: 24px;
            transform: translateY(-50%);
            transition: transform .25s ease-in-out;
        }
        .arrow-active {
            transform: rotate(-180deg);
            top: 45%;
        }
    }

}
.answer {
    display: ${({ open }) => (open ? 'block' : 'none')};
    padding: 1rem;
    /* transition: opacity .1s, visibility .5s, max-height .25s, padding .25s ease-in-out;
    opacity: ${({ open }) => (open ? '1' : '0')};
    visibility: ${({ open }) => (open ? 'visable' : 'hidden')};
    padding: ${({ open }) => (open ? '1rem' : '0')};
    max-height: ${({ open }) => (open ? '1000rem' : '0')}; */
    background-color: #000;
    div p, a {
        color: #fff;
        @media ${media.md} {
            font-size: 1.2rem!important;
        }
    }
    div a {
        color: #fff!important;
        &:hover, &:active {
            color: ${props => props.theme.colors.secondary}!important;
            text-decoration: underline!important;
        }
    }
}

    `
const Faq = ({ data }) => {
    return (
        <FaqStyles>
            <section>
                <h3>FREQUENTLY <br/> ASKED QUESTIONS</h3>
            </section>
            <div className="wrapper">
                {data.map((section, i) => (
                    <div className="question-block" key={i}>
                        <h4>{section.section}</h4>
                        <div className="grid">
                           {section.questions.map((question, i) => (
                                <SingleFaq data={question} key={i} />
                           ))}
                        </div>
                    </div>
                ))}
            </div>
        </FaqStyles>
    )
}

export default Faq


const SingleFaq = ({ data }) => {
    const [open, setOpen] = useState(false)
    return (
        <SingleFaQStyles onClick={() => setOpen(!open)} open={open}>
            <div className="question">
                <p>{data.question}</p>
                <span>
                    <img src={ArrowIcon} className={open ? 'arrow-active' : ''} />
                </span>
            </div>
            <div className={open ? 'answer' : 'answer'}>
                <div dangerouslySetInnerHTML={{ __html: data.answer }} />
            </div>
        </SingleFaQStyles>
    )
}
