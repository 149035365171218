import React from 'react';
import styled from 'styled-components'
import {media} from '../../utils/Media'
import moment from 'moment'

const PerformanceScheduleStyles = styled.section`
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes slide-in-bottom {
    0% {
      transform: translateY(1000px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 100%;
  display: flex;
  background-color: rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(25px);
  display: flex;
  justify-content: center;
  align-items: center;

  .container {
    animation: slide-in-bottom 0.5s 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    position: relative;
    padding: 1rem;
    height: 90%;
    width: 90%;
    background-color: #fff;
    border-radius: 5px;

    > section {
      width: 100%;
      height: 100%;
      overflow-y: scroll !important;
      overflow-x: hidden !important;

      h4 {
        text-align: center;
        font-size: 1.25rem !important;
        letter-spacing: 2px;
        font-family: ${(props) => props.theme.font.family.bold};
        text-transform: uppercase;
        font-weight: 700;
        margin-bottom: 1rem;

        span {
          text-decoration: underline ${(props) => props.theme.colors.secondary};
        }

        @media ${media.md} {
          font-size: 1.5rem !important;
          padding: 0 1.5rem;
        }
      }

      .wrapper {
        width: 90%;
        margin: 0 auto;
        @media ${media.md} {
          //display: grid;
          //grid-template-columns: 1fr 1fr;
        }
        @media ${media.lg} {
          //grid-template-columns: 1fr 1fr 1fr;
        }
      }

      .close {
        position: absolute;
        top: -1rem;
        right: -1rem;
        background-color: ${(props) => props.theme.colors.secondary};
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        div {
          background-color: #fff;
          width: 20px;
          height: 2px;
          transform-origin: 8px;

          &:nth-child(1) {
            transform: rotate(45deg);
          }

          &:nth-child(2) {
            transform: rotate(-45deg);
            /* margin-top: 5px; */
          }
        }

        @media ${media.md} {
          /* top: 2rem;
          right: 6.15rem; */
        }
        @media ${media.lg} {
          /* top: calc(0% + 4%);
          right: calc(0% + 14.5%); */
        }
      }


    }

    @media ${media.md} {
      width: 70%;
      max-width: 800px;
      max-height: 1000px;
      padding: 2rem;

    }
  }

`
const SingleDateStyles = styled.section`
  width: 100%;
  margin-bottom: 1rem;

  strong {
    font-size: 1.5rem !important;
    letter-spacing: 1.5px;
    text-align: center;
    display: block;
    color: ${props => props.theme.colors.secondary};
    font-family: ${props => props.theme.font.family.bold};
  }

  ul {
    list-style: none;
    margin: .5rem 0;
    padding: 0;

    li {
      color: #000;
      text-align: center;
      margin: 0.45rem 0;
      font-size: 0.8rem;
      font-weight: 600;
      @media ${media.md} {
        font-size: 0.85rem;
      }
    }
  }
`;

function GetDates({data}) {
    return (
        data.map((month, i) => {
            console.log(month.month.lastDate)
            if (moment(month.month.lastDate, "DD-MM-YYYY") >= moment()) {
                return <SingleDates data={month} key={i}/>;
            }
        })
    )
}

const PerformanceSchedule = ({data, setOpen}) => {
    return (
        <PerformanceScheduleStyles onClick={() => setOpen(false)}>
            <div className="container">
                <section>
                    <h4 className='text--red text-uppercase'>Peter Andre will be performing on the following dates</h4>
                    <div className="wrapper">
                        <GetDates
                            data={data}/>
                    </div>
                    <div className="close" type="button" onClick={() => setOpen(false)}>
                        <div></div>
                        <div></div>
                    </div>
                </section>
            </div>
        </PerformanceScheduleStyles>
    );
};

export default PerformanceSchedule;

const SingleDates = ({data}) => (
    <SingleDateStyles>
        <strong>{data.month.text}</strong>
        <ul>
            {data.month.dates.map((date, i) => {
                if (moment(date.validDate, "DD-MMMM-YYYY") >= moment()) {
                    return <li key={i}>{date.date}</li>;
                }
            })}
        </ul>
    </SingleDateStyles>
);
